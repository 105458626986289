@import '@hxm/styles/config';

.h1 {
  @include title-xlarge;
}

.h2 {
  @include title-large;
}

.h3 {
  @include title-medium;
}

.h4 {
  @include title-small;
}
