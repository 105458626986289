/* stylelint-disable no-descending-specificity */
@import '@hxm/styles/config';

.richText {
  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }

  // cant loop this dynamically (or at least not in a readable way)
  h1 {
    @include title-xlarge;
  }

  h2 {
    @include title-large;
  }

  h3 {
    @include title-medium;
  }

  // loop through block elements and add some generic / adjacent selector styles
  @each $el in $block-article-elements {
    #{$el}:empty {
      display: none;
    }

    #{$el} {
      margin-top: $text-spacing;
      margin-bottom: $text-spacing;
    }

    // heading gets more spacing above when adjacent to a block el
    @each $heading in $heading-types {
      @if index('h1' 'h2' 'h3', $heading) {
        #{$el} + #{$heading} {
          margin-top: $text-spacing * 2;
        }
      } @else {
        #{$el} + #{$heading} {
          margin-top: $text-spacing;
        }
      }
    }
  }

  @each $heading in $heading-types {
    @if index('h1' 'h2' 'h3', $heading) {
      #{$heading} {
        margin-bottom: $text-spacing;
      }
    }

    &:empty {
      display: none;
    }
  }

  hr {
    margin-top: $text-spacing * 2;
    margin-bottom: $text-spacing * 2;
  }

  ul,
  ol {
    padding-left: 30px;

    list-style-position: outside;

    @media (min-width: $min-tablet) {
      padding-left: 50px;
    }

    > li > ul,
    > li > ol {
      margin: 0.25em 0;
      padding-left: 1em;
    }
  }

  ul {
    list-style: disc inside;

    li {
      display: list-item;

      &:has(> ul) {
        // margin-top: 0.25em;
      }

      li {
        list-style-type: circle;
      }
    }
  }

  ol {
    list-style: decimal inside;

    ol {
      counter-reset: 0;

      li {
        list-style: lower-alpha inside;
      }
    }
  }

  dt {
    font-weight: 700;
  }

  dd {
    margin-bottom: $text-spacing;

    &:last-child {
      margin-bottom: 0;
    }
  }

  b,
  strong {
    font-weight: 700;
  }

  em,
  i {
    font-style: italic;
  }

  code,
  pre {
    border-radius: 3px;
    border: 1px solid var(--color-border);

    font-family: $font-family-monospace;

    background-color: lighten(#000, 90%);
    color: #e01e5a;
  }

  code {
    padding: 0.1em 0.3em;
  }

  pre {
    padding: var(--gutter);

    overflow: auto;

    code {
      padding: 0;

      border: 0;

      background: none;
    }
  }
}
